import { Avatar, BadgeV2, BlankState, Headline, Icon, LayoutBox } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { FloatingIconButton, IconButton } from '@fabric/button';
import classNames from 'classnames';

import './access-list.styl';

export function AccessList(props) {
	const {
		data,
		isProcessing,
		onClick,
		onRemove,
		renderBottomSlot,
		renderRightSlot,
		settings,
		subTitle,
	} = props;
	const uniqueUsers = [];

	data.filter((dataUser) => {
		if (!uniqueUsers.some(user => Number(user.id) === Number(dataUser.id))) {
			uniqueUsers.push(dataUser);
		}
	});

	const renderHeader = ifFeature('encore',
		() => (
			<Headline size='extra-small' color='neutral-medium'>
				{subTitle || $.__('Who has access')}
			</Headline>
		),
		() => (
			<h4>
				{subTitle || $.__('Who has access')}
			</h4>
		)
	);

	return (
		<section className="AccessList">
			{renderHeader()}
			<div className="AccessList__body">
				{
					ifFeature('encore',
						uniqueUsers.length > 0 ?
							uniqueUsers.map(row => _getAccessListRow(
								row,
								settings,
								isProcessing,
								onRemove,
								onClick,
								renderBottomSlot,
								renderRightSlot
							)) :
							<LayoutBox marginTop={3}>
								<BlankState
									title={$.__('No one has access yet.')}
									icon="bs-tumbleweed"
									level="page"
								/>
							</LayoutBox>,
						uniqueUsers.map(row => _getAccessListRow(
							row,
							settings,
							isProcessing,
							onRemove,
							onClick,
							renderBottomSlot,
							renderRightSlot
						)))
				}
			</div>
		</section>
	);
}

const _generateScheduleSummary = (reportScheduler) => {
	const { interval, timePeriod, daysOfWeek } = reportScheduler;
	if (timePeriod && Array.isArray(timePeriod)) {
		const checkPlurality = interval > 1 ? 's' : '';
		if (timePeriod.includes('week') && daysOfWeek && Array.isArray(daysOfWeek)) {
			return (
				<Message
					params={[interval, timePeriod[0], checkPlurality, daysOfWeek[0]]}
					text={$._('Emailed every {1} {2}{3} on {4}')}
				/>
			);
		}
		return (
			<Message
				params={[interval, timePeriod[0], checkPlurality]}
				text={$._('Emailed every {1} {2}{3}')}
			/>
		);
	}
};

const determineBottomSlotContent = (renderBottomSlot, reportScheduler, type) => {
	// Sorted by priority
	if (reportScheduler) { return <span className="AccessList__type">{_generateScheduleSummary(reportScheduler)}</span>; }
	if (renderBottomSlot) { return <span className="AccessList__type">{renderBottomSlot}</span>; }
	if (type) { return <span className="AccessList__type">{type}</span>; }
};

const displayRemoveButton = (row, settings) => {
	const {
		showRemoveButton = true,
		hideRemoveButtonForOwner = false,
		hideRemoveButtonForSelf = false,
	} = settings;
	const {
		owner = false,
		rowRemoveButton = true,
		self = false,
	} = row;

	if (showRemoveButton) {
		if (rowRemoveButton &&
			!((owner && hideRemoveButtonForOwner) || (self && hideRemoveButtonForSelf))
		) {
			return true;
		}
		return false;
	}
};

function _getAccessListRow(
	row,
	settings,
	isProcessing,
	onRemove,
	onClick,
	renderBottomSlot,
	renderRightSlot
) {
	const {
		ownerText = $.__('Owner'),
	} = settings;
	const {
		image,
		name,
		type,
		reportScheduler,
		owner,
		self,
	} = row;

	// @startCleanup encore
	const getGroupIcon = () => (
		<span className="AccessList__avatar Avatar">
			<Icon
				brand={true}
				name="fab-people-circle-30x30"
			/>
		</span>
	);
	// @endCleanup encore

	const getUserBadge = () => {
		let icon;

		if (image) {
			icon = <Avatar
				size={44}
				src={image}
			/>;
		} else {
			icon = "users-regular";
		}

		return ifFeature('encore',
			<>
				<BadgeV2
					icon={icon}
					size='small'
					title={name}
					subtitle={owner ? $.__(ownerText) : determineBottomSlotContent(renderBottomSlot, reportScheduler, type)}
				/>
			</>,
			<>
				{image ?
					(
						<img
							alt="employee"
							className="AccessList__avatar Avatar"
							src={image}
						/>
					) : getGroupIcon()}
				<div className={classNames('AccessList__rowRow', { AccessList__rowColumn: type })}>
					<span className={classNames('AccessList__name', { 'AccessList__name--owner': owner || self })}>
						{BambooHR.Utils.htmldecode(name)}
						{owner && (<span className="AccessList__name--subtext">({ownerText})</span>)}
					</span>
					{determineBottomSlotContent(renderBottomSlot, reportScheduler, type)}
				</div>
			</>
		);
	};

	const getRowLeftContainer = (content) => {
		if (onClick && !owner && !self) {
			return (
				<button
					className="AccessList__clickableContainer"
					onClick={() => onClick(row)}
					type="button"
				>
					{content}
				</button>
			);
		}

		return content;
	};

	return (
		<div key={name} className="AccessList__row">
			{getRowLeftContainer(getUserBadge())}
			<div className="AccessList__rowRight">
				{renderRightSlot && renderRightSlot(row)}

				{_renderIndicator(row, onRemove, settings, isProcessing)}
			</div>

		</div>
	);
}

/**
 * Renders view of owner or remove button
 * @param     {object}   row      Employee Access List Data
 * @param     {function} onRemove Callback when an employee is removed from the list
 * @param     {object}   settings
 * @return    {jsx}               Owner indicator or remove button
 */

function _renderIndicator(row, onRemove, settings, isProcessing) {
	// @startCleanup encore		
	const showRemoveButton = displayRemoveButton(row, settings);
	let jadeButton;

	if (showRemoveButton) {
		jadeButton = (
			<span className="AccessList__rowRight__icon">
				<FloatingIconButton
					clickAction={() => onRemove(row)}
					hasBounding={false}
					icon="fab-x-14x14"
					isDisabled={isProcessing}
					secondary={true}
				/>
			</span>
		);
	} else {
		jadeButton = (
			<span className="AccessList__rowRight--spacer"></span>
		);
	}
	// @endCleanup encore

	return ifFeature('encore',
		<span className={classNames('AccessList__rowRight__icon', { 'AccessList__rowRight--hidden': !showRemoveButton })}>
			<IconButton
				aria-label={$.__('Remove Access')}
				icon='xmark-solid'
				onClick={() => onRemove(row)}
				size='small'
				type='button'
				variant='outlined'
			/>
		</span>,
		jadeButton
	);
}
