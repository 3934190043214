import {
	BodyText,
	Divider,
	Flex,
	Headline,
	Icon,
	IconV2,
	LayoutBox,
	Link,
} from '@bamboohr/fabric';
import { Message } from '@bamboohr/utils/lib/message';
import moment from 'moment.lib';
import { ReactElement } from 'react';
import { EmployeeInfo } from '../EmployeeInfo';
import { styles } from './styles';

import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Employee } from 'NHPTemplates.mod/Previewer/Components/EmployeeInfo/employeeInterface';

interface Props {
	withHeader: boolean;
	companyName: string;
	employeeName: string;
	firstDayDate: string;
	contact: Employee;
	arriveByTime: string;
	location: string;
	otherInstructions: string;
}

function formatTime(time: string): string {
	const formattedTime = moment(time, [moment.ISO_8601, 'hh:mm:ss']).format(
		'h:mm A',
	);
	return formattedTime === 'Invalid date' ? null : formattedTime;
}

function formatDate(date: string): string {
	let formattedDate;
	const format = moment.defaultFormat;
	if (date) {
		formattedDate = moment(date).format(format);
	} else {
		return undefined;
	}
	return formattedDate === 'Invalid date' ? undefined : formattedDate;
}

export function WhenAndWhere(props: Props): ReactElement {
	const classes = styles();

	const {
		withHeader,
		firstDayDate,
		employeeName,
		companyName,
		contact,
		arriveByTime,
		location,
		otherInstructions,
	} = props;

	const parsedFirstDayDate: string = formatDate(firstDayDate);

	const headerTitle = (
		<Message
			params={[employeeName]}
			text={ifFeature(
				'encore',
				$._('Welcome Aboard, {1}!'),
				$._('Welcome Aboard, {1}!'),
			)}
		/>
	);
	const headerSubtitle = (
		<Message
			params={[companyName]}
			text={$._(
				`We are excited that you are joining us here at {1}. We can't wait for you to start. We've included some helpful information and ask that you complete this new employee packet so you can hit the ground running on your first day. If you have any questions, please don't hesitate to ask.`,
			)}
		/>
	);

	return ifFeature(
		'encore',
		<Flex flexDirection="column" flexGrow={1}>
			{withHeader && (
				<Flex flexDirection="column">
					<LayoutBox marginBottom="4px">
						<Headline color="primary" size="medium">
							{headerTitle}
						</Headline>
					</LayoutBox>
					<LayoutBox marginBottom={2}>
						<BodyText size="extra-small">{headerSubtitle}</BodyText>
					</LayoutBox>
					<Divider
						color="neutral-extra-weak"
						marginBottom={'24px'}
						marginTop={'16px'}
					/>
				</Flex>
			)}

			<Flex flexDirection="row" justifyContent="space-between">
				<Flex flex="50%" flexDirection="column" gap="20px">
					<Headline size="small">{$._('Your First Day')}</Headline>
					<Flex flexDirection="column" gap={2}>
						<Flex alignItems="center" gap="12px">
							<IconV2
								color="primary-strong"
								name="calendar-star-regular"
								size={20}
							/>
							<Headline color="neutral-strong" size="extra-small">
								{parsedFirstDayDate ||
									moment(Date.now())
										.add(14, 'days')
										.format(moment.defaultFormat)}
							</Headline>
						</Flex>
						{arriveByTime && (
							<Flex alignItems="center" gap="12px">
								<IconV2 color="primary-strong" name="clock-regular" size={20} />
								<Headline color="neutral-strong" size="extra-small">
									{formatTime(arriveByTime)}
								</Headline>
							</Flex>
						)}
						{location && (
							<Flex flexDirection="column" gap="4px">
								<Flex alignItems="center" gap="12px">
									<IconV2
										color="primary-strong"
										name="location-dot-regular"
										size={20}
									/>
									<Headline color="neutral-strong" size="extra-small">
										{location}
									</Headline>
								</Flex>
								<LayoutBox marginLeft={4}>
									<Link
										href={`https://maps.google.com/?q=${location}`}
										rel="noopener noreferrer"
										target="_blank"
									>
										<Flex alignItems="center" gap="8px">
											<IconV2 name="compass-regular" size={16} />
											<BodyText size="extra-small">Map & Directions</BodyText>
										</Flex>
									</Link>
								</LayoutBox>
							</Flex>
						)}
					</Flex>
				</Flex>

				<Flex flex="50%" flexDirection="column" gap={4}>
					{contact && (
						<Flex flexDirection="column" gap={2}>
							<Headline color="primary" size="small">
								{$._('Who to Contact')}
							</Headline>
							<EmployeeInfo employee={contact} avatarSize={64} />
						</Flex>
					)}
					{otherInstructions && (
						<Flex flexDirection="column" gap={2}>
							<Headline color="primary" size="small">
								{$._('Other Instructions')}
							</Headline>
							{otherInstructions.split('\n').map((i, key) => {
								return <BodyText key={key}>{i}</BodyText>;
							})}
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>,
		<div>
			{withHeader && (
				<div className={classes.header}>
					<h2 className={classes.headerTitle}>{headerTitle}</h2>
					<p className={classes.headerDesc}>{headerSubtitle}</p>
					<hr className={classes.divider} />
				</div>
			)}

			<div className={classes.boxContent}>
				<div className={classes.leftContent}>
					<div>
						<h2>{$._('Your First Day')}</h2>
						<ul className={classes.firstDayList}>
							<li>
								<div>
									<div className={classes.firstDayIcon}>
										<Icon brand={true} name="fab-calendar-24x24" />
									</div>
									<span className="spacer">
										{parsedFirstDayDate ||
											moment(Date.now())
												.add(14, 'days')
												.format(moment.defaultFormat)}
									</span>
								</div>
							</li>
							{arriveByTime && (
								<li>
									<div>
										<div className={classes.firstDayIcon}>
											<Icon brand={true} name="fab-clock-24x24" />
										</div>
										<span className="spacer">{formatTime(arriveByTime)}</span>
									</div>
								</li>
							)}
							{location && (
								<li>
									<div>
										<div className={classes.firstDayIcon}>
											<Icon brand={true} name="fab-location-18x24" />
										</div>
										<span className="spacer">{location}</span>
									</div>
									<div className={classes.directionsLink}>
										<a
											href={`https://maps.google.com/?q=${location}`}
											rel="noopener noreferrer"
											target="_blank"
										>
											<Icon name="fab-compass-14x14" />
											<span>Map & Directions</span>
										</a>
									</div>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className={classes.rightContent}>
					{contact && (
						<div className={classes.contactContainer}>
							<h2>{$._('Who to Contact')}</h2>
							<EmployeeInfo employee={contact} />
						</div>
					)}
					{otherInstructions && (
						<div className={classes.otherInstructions}>
							<h2>{$._('Other Instructions')}</h2>
							{otherInstructions.split('\n').map((i, key) => {
								return <p key={key}>{i}</p>;
							})}
						</div>
					)}
				</div>
			</div>
		</div>,
	);
}
