import { StyledBox } from '@bamboohr/fabric';
import { ReactNode } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

type Props = {
	currentPage: number;
	pageCount: number;
	loading: boolean;
	routes: {
		path: string;
		component: ReactNode;
	}[];
};

const ContentRouter = ({ currentPage, pageCount, loading, routes }: Props) => {
	const onLastPage = currentPage === pageCount;

	return (
		<HashRouter
			basename="/preview"
			hashType="noslash"
			key={`${currentPage}_${loading}`}
		>
			<Switch>
				{routes.map((route) => (
					<Route path={route.path}>
						<StyledBox
							backgroundColor="neutral-forced-white"
							borderRadius="large"
							boxShadow="large"
							flexGrow={1}
							marginTop={onLastPage ? 0 : '40px'}
							padding={'32px 40px'}
						>
							{route.component}
						</StyledBox>
					</Route>
				))}
			</Switch>
		</HashRouter>
	);
};

export default ContentRouter;
