export enum FieldTypes {
	FIELD_ROW = 'FieldRow',
	FIELD_SET = 'FieldSet',
	GROUP = 'Group',
	FORM = 'Form',
	TEXT = 'TextField',
	SELECT = 'SelectField',
	CHECKBOX = 'CheckboxField',
	CHECKBOX_LIST = 'CheckboxList',
	DATE = 'DateField',
	REPEAT = 'RepeatingContainer',
	STATIC = 'StaticTemplate',
	INFORMATION = 'InformationField',
	TEXTAREA = 'TextareaField',
	HIDDEN = 'HiddenInput',
	LEGEND = 'Legend',
	EMPLOYEE_ACCESS = 'EmployeeAccess',
	DOCUMENTS = 'Documents',
}

export enum Fields {
	emailHome = 'home-email-field',
	emailWork = 'work-email-field',
	employeeAddressLine1 = 'textEmployeeAddressLine1',
	employeeAddressLine2 = 'textEmployeeAddressLine2',
	employeeAddressCity = 'textEmployeeCity',
	employeeAddressState = 'employee-address-state-field',
	employeeAddressStateId = 'selectEmployeeStateId',
	employeeAddressZipCode = 'textEmployeeZipCode',
	employeeAddressCountry = 'selectEmployeeCountryId',
	employeeFirstName = 'first-name-field',
	employeeLastName = 'last-name-field',
	employeeMiddleName = 'middle-name-field',
	employeeNumber = 'employee-number-field',
	employeeTaxType = 'employee-tax-type-field',
	employmentStatus = 'employment-status-field',
	gender = 'gender-field',
	genderIdentity = 'gender-identity-field',
	genderPronouns = 'gender--pronouns-field',
	homeState = 'state-field',
	location = 'js-location-insertable-list',
	nationalId = 'national-id-field',
	nin = 'nin-field',
	overtimeRate = 'overtime-rate-field',
	overtimeStatus = 'overtime-status-field',
	paidPer = 'paid-per-field',
	payGroup = 'pay-group-field',
	payRate = 'pay-rate-field',
	payrollHomeStateField = 'js-validate-payroll-state-live-in',
	paySchedule = 'pay-schedule-field',
	payType = 'pay-type-field',
	sin = 'sin-field',
	ssn = 'ssn-field',
	stateTaxWithholdingLocationField = 'js-payroll-tax-state-withholding-type',
	stateUiExemptField = 'js-payroll-tax-state-ui-exempt',
	stateUiLocationField = 'js-payroll-tax-state-ui-type',
}

export enum FieldLookupValues {
	token = 'settings.token',
	className = 'props.className',
}
